<template>
  <div :style="slidePosition" class="z-6 w-100 h-100">
    <div ref="spiralWrapper19"
      class="position-absolute d-flex flex-column align-items-start justify-content-center z-3 spiralWrapper19 rotate19 z-7"
      style=" width: 180vh; height: 180vh; top:50%; left:50%;transform: translate(-50%, -50%); opacity:0;">
      <img src="images/Slide19/Kamiokande_1.png" class="spiral19 w-100 h-100" style="" />
    </div>
    <div
      class="position-absolute d-flex flex-column align-items-start justify-content-center rounded-circle circle19 z-4 border-warning"
      style="background: rgb(0,161,237); width:120vh; height:120vh; top:50%; left:50%; padding: 8vw; background: rgb(0,161,237); background: linear-gradient(0deg, rgba(0,161,237,1) 0%, rgba(0,108,169,1) 85%); transform: translate(-50%, -50%) scale(0);border:solid 0.75vh;">
      <h6 class="text-white font-vw-3 fade19" style="opacity:1; ">
        2001
      </h6>
      <h6 class="text-white font-vw-2-5 fade19 my-0" style="opacity:1; ">
        HUNTING NEUTRINOS
      </h6>
      <p class="mb-0 mt-3 text-19 text-white font-vw-1-35" style="opacity:1; ">
        Neutrinos have little mass, but as the most abundant particles in the
        cosmos, they’re a big deal. In 2001, TRIUMF was the first international
        collaborator to join Japan’s Tokai to Kamioka (T2K) experiment,
        developing new techniques and technologies to increase the experiment’s
        sensitivity. TRIUMF continues to drive next- generation neutrino and
        dark matter searches at both T2K and Canada’s own SNOLAB in Sudbury,
        Ontario, where TRIUMF researchers contributed to the SNO collaboration
        and to Arthur McDonald’s 2015 Nobel Prize in Physics.
      </p>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { slidePositionPlain } from "@/utils/slidePositioning"
//import { normalize } from "@/utils/ranges"
export default {
  data() {
    return {}
  },
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  methods: {
    circle19(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 6000
      })

      animation
        .add({
          targets: ".circle19",
          scale: [0, 1],
          translate: ("50%", "0%"),
          duration: 100,

          delay: (el, i) => 5000 + 30 * i,
          easing: "easeOutCubic"
        })
        .add({
          targets: ".fade19",

          translateY: ["-50%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine",
          delay: (el, i) => 1000 + 30 * i
        })
        .add({
          targets: ".text-19",

          opacity: [0, 1],

          translateY: ["35%", "0%"],
          duration: 100,
          easing: "easeInOutSine"
        })
      animation.seek(
        percentage <= 0.5
          ? animation.duration * (2 * percentage)
          : animation.duration * 1
      )
    },

    spiralWrapperFade(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 200
      })

      animation
        .add({
          targets: ".spiralWrapper19",
          opacity: [0, 1],
          duration: 600,
          delay: 0,
          easing: "linear"
        })
        .add({
          targets: ".spiralWrapper19",
          opacity: [1, 0],
          duration: 400,
          delay: 0,
          easing: "linear"
        })

      animation.seek(animation.duration * percentage)
    },
    startLoop() {
      anime({
        loop: true,
        targets: ".rotate19",
        rotate: "-5turn",
        direction: "alternate",
        duration: 300000,
        easing: "linear"
      })
    }
  },
  mounted() {
    this.startLoop()
  },
  watch: {
    innerPosition() {
      this.spiralWrapperFade(this.innerPosition)
      this.circle19(this.innerPosition)
    }
  }
}
</script>

<style></style>
